.nav {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-direction: column;
  width: 80px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  background: #0181ff;
  background-image: linear-gradient(to bottom, #0181ff, #21a5f9);
  box-shadow: 2px 0 16px rgba(#000, 0.1);
  color: #3b3b3b;

  &.light {
    box-shadow: none;
  }

  @media screen and (max-width: 992px) {
    position: sticky;
    top: 0;

    width: 100%;
    height: 112px;
    padding-top: 32px;
    flex-direction: row;
    justify-content: space-between;
    right: 0;
    left: auto;
    bottom: auto;

    background-image: linear-gradient(to right, #0181ff, #21a5f9);
    box-shadow: 0 2px 4px rgba(#000, 0.1);
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    // padding: 32px;

    list-style: none;

    @media screen and (max-width: 992px) {
      flex-direction: row;
      margin-right: 16px;
    }
  }

  li {
    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 8px;

      @media screen and (max-width: 992px) {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }

    img {
      width: 24px;
      height: auto;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    border-radius: 8px;
    color: #fff;

    opacity: 0.8;

    font-size: 0;
    line-height: 0;
    text-decoration: none;
    white-space: nowrap;

    transition: 0.2s ease;

    &:hover .tooltip {
      opacity: 1;
    }

    &:hover,
    &.active {
      // background: #0683ed;
      background: rgba(#000, 0.08);
      opacity: 1;
    }
  }

  .tooltip {
    display: flex;
    height: 12px;
    margin-left: 26px;

    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -7px;

    color: #fff;
    opacity: 0;
    transition: 0.25s ease;
    pointer-events: none;

    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    z-index: 100;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .tooltip {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    margin-top: -12px;
    margin-left: 4px;
    position: relative;

    background: rgba(#333, 0.85);
    border-radius: 3px;
    // box-shadow: 0 2px 4px rgba(#000, 0.1);

    color: #fff;

    &::before {
      position: absolute;
      top: 9px;
      left: -16px;
      width: 16px;
      height: 8px;

      border-style: solid;
      border-width: 4px 4px 4px 0;
      border-color: transparent;
      border-right-color: rgba(#333, 0.85);
      content: '';
    }
  }

  .logo {
    width: auto;
    height: 48px;
    margin-top: 16px;
    margin-bottom: 64px;

    @media screen and (max-width: 992px) {
      margin: 0 32px 0 16px;
    }
  }
}

.nav-bottom {
  position: absolute;
  bottom: 16px;
}
