.table {
  width: 100%;
  margin-top: 32px;

  table-layout: fixed;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(#000, 0.05);

  thead {
    border-radius: 8px;

    background: #0181ff;
    box-shadow: 0 2px 4px rgba(#000, 0.1);
    background-image: linear-gradient(to right, #0181ff, #21a5f9);
  }

  tbody > tr:last-child {
    td {
      background: #fff;
    }

    td:first-child {
      border-bottom-left-radius: 8px;
    }

    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid #f6f6fa;
  }

  td,
  th {
    padding: 12px 16px;

    color: #282d5b;

    font-size: 14px;
    font-weight: 400;
  }

  th {
    padding: 16px;

    // background: #0181ff;
    color: #fff;
    // border-bottom: 1px solid #fff;

    font-weight: 600;
    text-align: left;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.resource {
  display: flex;
  align-items: center;

  small {
    color: #888b9f;
  }
}

.muted {
  color: #888b9f;
}

.actions {
  text-align: right;

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;

    background: #f6f6fa;
    border: 0;
    border-radius: 4px;
    color: #a8abbe;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    font-size: 14px;
    font-weight: 600;

    transition: 0.25s ease;

    &:hover {
      background: #f3f3f7;
      color: #0181ff;
    }

    &.danger:hover {
      color: #ed1c24;
    }

    img {
      width: 16px;
      height: auto;
    }
  }
}

p {
  color: #a8abbe;

  font-weight: 400;
}
