.search-input {
  width: 240px;
  padding: 12px 16px;

  background: #fff;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(#000, 0.07);
  color: #393d67;
  outline: none;

  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b6b6b6;
  }
}
