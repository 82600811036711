.instructor-list {
  margin-top: 24px;
}

.instructor-card {
  padding: 16px;
  margin-bottom: 16px;

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(#000, 0.05);

  font-size: 14px;
}

.instructor-card-heading {
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
}
