.dropdown {
  display: inline-block;
  position: relative;

  button {
    display: flex;
    align-items: center;

    background: transparent;
    border: 0;
    color: #393d67;

    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    font-size: 14px;
    line-height: 1;

    transition: 0.25s ease;

    @media screen and (max-width: 992px) {
      font-size: 16px;
    }

    &:hover,
    &:focus {
      color: #007fff;
    }

    &.dropdown-button::after {
      display: block;
      width: 14px;
      margin-left: 8px;
      height: 9px;

      background-image: url('./chevron-down.svg');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
    }
  }
}

.dropdown-overlay {
  position: absolute;
  top: 100%;
  min-width: 120px;

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(#000, 0.25);
  z-index: 11;

  .right & {
    right: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  button {
    display: block;
    width: 100%;
    padding: 12px 16px;

    background: transparent;
    border: 0;

    text-align: left;

    small {
      display: block;
      margin-top: 4px;

      color: #888b9f;
    }
  }
}
