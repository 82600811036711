.autocomplete {
  margin: 0;
  padding: 18px 32px;

  background: transparent;
  border: 0;
  color: #393d67;
  outline: none;

  font-size: 14px;
  font-weight: 500;

  @media screen and (max-width: 992px) {
    width: 100%;

    font-size: 16px;
  }

  &::placeholder {
    color: #b6b6b6;
  }
}

.list-container {
  position: absolute;
  min-width: calc(200% + 1px);
  top: calc(100% - 5px);
  left: 0;
  max-height: 244px;
  overflow-y: auto;
  z-index: 11;

  background: #fff;
  border: 0; // 1px solid #efefef;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(#000, 0.25);

  @media screen and (max-width: 992px) {
    min-width: 100%;
    left: -8px !important;
    right: -8px;

    border: 1px solid #efefef;
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  li:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }

  button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    min-height: 56px;

    background: transparent;
    border: 0;
    color: #393d67;
    outline: none;
    cursor: pointer;

    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;

    transition: 0.2s ease;

    @media screen and (max-width: 992px) {
      font-size: 16px;
    }

    small {
      color: #888b9f;
    }

    &.active {
      background: #fafaff;
      color: #007fff;
    }

    &:hover,
    &:focus {
      background: #fafaff;
      color: #007fff;
    }
  }
}

.avatar {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  flex-shrink: 0;

  background-color: #f6f6fa;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
}

.selected-choice {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 16px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 8px;
  background: #fff;
  color: #393d67;
  cursor: pointer;

  font-size: 14px;
  line-height: 1.1;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;

  transition: 0.2s ease;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }

  &:hover {
    background: #fafaff;
    color: #007fff;
  }

  small {
    color: #888b9f;
  }
}
