.wrapper {
  position: relative;
  flex: 1;
  flex-shrink: 0;
  width: 50%;

  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media screen and (max-width: 992px) {
    width: 100%;

    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  :global(.autocomplete-dropdown) {
    left: calc(-100% - 1px);
  }
}

// .list-container {
//   position: absolute;
//   top: calc(100% - 5px);
//   left: -100%;
//   min-width: 200%;
//   max-height: 244px;
//   overflow-y: auto;
//   z-index: 10;

//   background: #fff;
//   border: 1px solid #efefef;
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(#000, 0.2);

//   @media screen and (max-width: 992px) {
//     left: 0;
//     min-width: 100%;
//     left: -8px;
//     right: -8px;

//     border: 1px solid #efefef;
//   }

//   ul {
//     margin: 0;
//     padding: 0;

//     list-style: none;
//   }

//   li:not(:last-child) {
//     border-bottom: 1px solid #efefef;
//   }

//   button {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     padding: 8px 16px;
//     min-height: 56px;

//     background: transparent;
//     border: 0;
//     color: #393d67;
//     outline: none;
//     cursor: pointer;

//     font-size: 14px;
//     line-height: 1.1;
//     font-weight: 400;
//     text-align: left;
//     white-space: nowrap;

//     transition: 0.2s ease;

//     small {
//       color: #888b9f;
//     }

//     &.active {
//       background: #fafaff;
//       color: #007fff;
//     }

//     &:hover,
//     &:focus {
//       background: #fafaff;
//       color: #007fff;
//     }
//   }
// }

// .avatar {
//   display: block;
//   width: 40px;
//   height: 40px;
//   margin-right: 16px;
//   flex-shrink: 0;

//   background-color: #f6f6fa;
//   background-image: url('./DefaultAvatar.png');
//   background-position: center center;
//   background-size: cover;
//   border-radius: 4px;
// }

// .instructor-choice {
//   display: flex;
//   align-items: center;
//   height: 100%;
//   padding: 8px 16px;

//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;

//   border-top-left-radius: 8px;
//   border-bottom-left-radius: 8px;
//   background: #fff;
//   color: #393d67;
//   cursor: pointer;

//   font-size: 14px;
//   line-height: 1.1;
//   font-weight: 400;
//   text-align: left;
//   white-space: nowrap;

//   transition: 0.2s ease;

//   &:hover {
//     background: #fafaff;
//     color: #007fff;
//   }

//   @media screen and (max-width: 992px) {
//     border: 0;
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 8px;
//     border-top-right-radius: 8px;
//   }

//   small {
//     color: #888b9f;
//   }
// }
