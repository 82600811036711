.content {
  padding-left: 80px;

  @media screen and (max-width: 992px) {
    padding-top: 0;
    padding-left: 0;
  }
}

.content-inner {
  margin: 0 auto;
  max-width: calc(100% - 128px);

  @media screen and (max-width: 992px) {
    max-width: calc(100% - 32px);
  }
}
