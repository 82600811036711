.full-schedule {
  position: relative;
  margin: 24px auto;
  width: 1240px;
  max-width: calc(100% - 32px);

  background: #fff;
  border-radius: 4px;
  color: #3a3a3a;
}

.heading {
  position: sticky;
  top: 0;
  z-index: 15;

  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #efefef;

  @media screen and (max-width: 992px) {
    position: static;
  }
}

.resource {
  display: flex;

  // border-top: 1px solid #efefef;

  &.last .cell {
    border-bottom: 0;
  }

  &.last .resource-name {
    border-bottom-left-radius: 4px;
  }
}

.resource-name {
  // position: sticky;
  // left: 0;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 280px;
  padding: 4px 16px;

  background: #fff;
  border-bottom: 1px solid #efefef;
  border-right: 1px solid #efefef;

  font-size: 14px;
  overflow: hidden;
  z-index: 10;

  @media screen and (max-width: 992px) {
    font-size: 14px;
    width: 240px;
  }

  small {
    color: #888b9f;
  }
}

.cell {
  flex-shrink: 0;
  flex: 1;

  border-bottom: 1px solid #efefef;
  color: #fff;
  cursor: pointer;

  transition: 0.1s ease;

  @media screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex: unset;
    flex-grow: 1;
    flex-shrink: 0;

    color: rgba(#888b9f, 0.6);

    font-size: 10px;
  }

  &.hover {
    background: #f1fff1;
  }

  &:not(:first-child) {
    border-left: 1px solid #efefef;

    &:nth-child(2n + 4) {
      border-left-width: 2px;
    }
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  color: #393d67;

  font-size: 14px;
  font-weight: 500;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.hours {
  display: flex;
  align-items: center;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;

  height: 32px;
  padding-left: 280px;

  background: #fff;
  border-bottom: 1px solid #efefef;

  @media screen and (max-width: 992px) {
    display: none;
  }

  .cell {
    border: 0;
    color: #888b9f;

    pointer-events: none;

    font-size: 12px;

    span {
      display: inline-block;

      transform: translateX(-50%);
    }
  }
}

.calendar {
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    overflow-x: auto;
  }
}

.label {
  color: #888b9f;
}

.date-picker-wrapper {
  position: relative;
}

.date-picker {
  position: absolute;
  top: 100%;
  left: -50%;
}

.date-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 16px 0;
    justify-content: space-between;

    border-bottom: 1px solid #efefef;
  }
}

.change-date-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;

  background: transparent;
  border: 0;
  border-radius: 50%;
  color: #007fff;
  line-height: 16px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  transition: 0.25s ease;

  @media screen and (max-width: 992px) {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    // margin: 0 8px;
  }

  &:hover,
  &:focus {
    background: #fafaff;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  svg {
    width: auto;
    height: 12px;
  }
}

.prev-day {
  margin-left: 8px;
}

.open-calendar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 20px;
  padding: 0;

  background: transparent;
  border: 0;
  color: #393d67;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  font-size: 14px;
  line-height: 1;

  transition: 0.25s ease;

  @media screen and (max-width: 992px) {
    width: 160px;
    font-size: 16px;
  }

  &:hover,
  &:focus {
    color: #007fff;
  }
}

.autocomplete-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  // width: 400px;

  @media screen and (max-width: 992px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    border-bottom: 1px solid #efefef;
  }

  input {
    border-right: 0;
  }
}

.duration-location-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 16px 0;
  }
}
