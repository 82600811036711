.toggle {
  position: relative;
  display: flex;
  align-items: center;
  // height: 36px;
  padding: 2px;
  margin-left: 8px;

  background: #fff;
  box-shadow: 0 1px 2px rgba(#000, 0.07);
  border-radius: 8px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0;
    padding: 0 32px;
    height: 40px;

    background: transparent;
    border: 0;
    border-radius: 8px;
    z-index: 2;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: 0.2s ease;

    color: #abb0c1;

    font-size: 13px;
    font-weight: 500;
    text-align: center;
    word-wrap: none;
    white-space: pre;

    &.active {
      color: #282d5b;
    }

    &:focus {
      outline: none;
    }
  }
}

.pill {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  width: 50%;

  background: #f3f3f7;
  border-radius: 8px;
  z-index: 0;
  transition: 0.2s ease;

  &.right {
    left: calc(50% - 2px);
  }
}
