.form-wrapper {
  width: 100%;
}

.form {
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  margin-top: 24px;

  background: #fff;
  border-radius: 8px;

  @media screen and (max-width: 992px) {
    width: calc(100vw - 32px);
  }
}

.form-fields {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    max-width: 100%;
    width: calc(100vw - 32px);
  }
}

.results {
  width: 992px;
  max-width: calc(100vw - 128px);
  margin: 0 auto 64px;

  @media screen and (max-width: 992px) {
    max-width: 100vw;
  }

  h3 {
    margin: 32px auto 16px;
    padding: 0 16px;
    max-width: 85%;

    font-size: 16px;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 992px) {
      font-size: 18px;
    }

    strong {
      font-weight: 600;
    }
  }
}

.slot-list {
  margin: 0;
  padding: 0;

  list-style: none;

  li {
    margin-bottom: 4px;

    @media screen and (max-width: 992px) {
      margin-bottom: 0;
      border-bottom: 1px solid #efefef;
    }
  }
}

.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 8px 16px;

  background: #fff;
  border: 0;
  border-radius: 4px;
  color: #393d67;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  font-size: 14px;
  line-height: 1;
  text-align: left;

  transition: 0.25s ease;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: normal;
    height: auto;
    padding: 16px;

    border-radius: 0;

    font-size: 16px;
  }

  &:hover {
    color: #007fff;
  }
}

.thumbnails {
  display: flex;
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 1;

  @media screen and (max-width: 992px) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-right: 0;
  }
}

.thumbnail-wrapper {
  display: flex;
}

.thumbnail {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 8px;

  background-color: #f6f6fa;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;

  @media screen and (max-width: 992px) {
    &:not(:first-child) {
      margin-right: 0;
    }
  }
}

.main-details,
.date-and-time {
  display: flex;
  height: 40px;
  padding: 2px 8px;
  flex-direction: column;
  justify-content: space-between;
}

.date-and-time {
  width: 200px;
  flex-shrink: 0;

  color: #888b9f;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 14px;
  }

  .date-row {
    display: flex;
    align-items: center;
  }

  .icon {
    width: auto;
    height: 16px;
    margin-right: 8px;

    opacity: 0.3;
  }

  .time-row {
    padding-left: 25px;
  }
}

.instructor-name {
  color: #888b9f;
}

.cta {
  padding: 8px 48px;

  background: #007fff;
  color: #fff;
  border-radius: 2px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: center;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 16px;
    padding: 12px 0;

    font-size: 18px;
  }

  small {
    display: block;
    margin-top: 4px;
    font-size: 12px;

    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }
}

.show-next-wrapper {
  text-align: center;
}

.show-next-button {
  width: 195px;
  margin: 16px auto;

  border: 0;
  background: #389eff;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
}

.error-message {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 540px;
  margin: 32px auto;
  padding: 0 8px;

  font-size: 14px;
  text-align: left;

  @media screen and (max-width: 992px) {
    margin: 32px auto;
    width: calc(100vw - 32px);

    font-size: 16px;
  }

  img {
    width: 32px;
    height: auto;
    margin-right: 16px;

    flex-shrink: 0;
  }
}
