.resource-button {
  display: flex;
  align-items: center;
  padding: 0;

  border: 0;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  color: #282d5b;

  font-size: 14px;
  font-weight: 400;
  text-align: left;

  transition: 0.25s ease;

  &:hover {
    color: #007fff;
  }
}

.description {
  color: #888b9f;

  font-size: 12px;
}
