.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 0;
  min-height: 44px;
  padding: 0;

  color: #282d5b;

  font-size: 22px;
  font-weight: 400;
}
