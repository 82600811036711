.avatar {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  flex-shrink: 0;

  background-color: #f6f6fa;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;

  &.instructor {
    background-image: url('./DefaultInstructor.png');
  }

  &.aircraft {
    background-image: url('./DefaultAircraft.png');
  }
}
