.switch {
  display: flex;
  height: 44px;
  padding: 2px;
  position: relative;

  background: #389eff;
  border-radius: 22px;
  overflow: hidden;

  .pill {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 113px;
    z-index: 0;

    background: #007fff;
    // background-image: linear-gradient(to right, #0181ff, #21a5f9);

    border-radius: 20px;

    transform: translate3d(0, 0, 0);
    transition: width 0.2s linear;
  }

  button {
    display: inline-block;
    padding: 0 24px;
    height: 40px;

    background: transparent;
    border: 0;
    border-radius: 20px;
    color: #fff;
    outline: none;
    cursor: pointer;
    z-index: 2;
    -webkit-tap-highlight-color: transparent;

    font-size: 14px;
    font-weight: 500;
    line-height: 40px;

    transition: 0.2s ease;

    &.active {
      color: #fff;
    }
  }
}
