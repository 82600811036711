.spinner {
  margin: 64px auto;

  color: #fff;

  font-size: 14px;
  font-weight: 500;
  text-align: center;

  svg {
    width: 32px;
    height: auto;

    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
