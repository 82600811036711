@import '~bootstrap/scss/bootstrap-grid';
@import '~react-datepicker/src/stylesheets/datepicker.scss';

$font-default: 'proxima-soft', 'Open Sans', 'Helvetica Neue LT Std', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

* {
  box-sizing: border-box;
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;

  background: #f4f8fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-appearance: none;
}

.react-datepicker {
  z-index: 11;

  box-shadow: 0 2px 4px rgba(#000, 0.25);
  border: 0;
  border-radius: 4px;
  color: #393d67;

  font-family: $font-default;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
}

.react-datepicker__header {
  background: #007fff;
  border: 0;
  color: #fff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: #393d67;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 50%;
  background: #007fff;
  color: #fff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 14px;

  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
}

.react-datepicker__navigation {
  top: 13px;

  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  opacity: 0.2;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: 600;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--previous:focus {
  border-right-color: #fff;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--next:focus {
  border-left-color: #fff;
}

.react-datepicker__current-month {
  margin-bottom: 4px;

  font-weight: 600;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 50%;
  background: #007fff;
  color: #fff;

  outline: none;
}
