.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-left: 80px;
  position: relative;

  background: #007fff;
  color: #fff;

  @media screen and (max-width: 992px) {
    padding-top: 0;
    padding-left: 0;
  }

  h2 {
    margin: 0 0 32px;
    padding: 0;

    font-size: 28px;
    font-weight: 400;
    line-height: 1;

    @media screen and (max-width: 992px) {
      margin-top: 60px;
    }
  }

  // h3 {
  //   margin: 0 0 32px;
  //   padding: 0;

  //   font-size: 14px;
  //   font-weight: 400;
  // }
}

.school-name {
  margin: 0;
  padding: 0;

  position: absolute;
  top: 8px;
  right: 16px;

  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  font-size: 13px;
  font-weight: 500;

  transition: 0.2s ease;

  &:hover {
    text-decoration: underline;
  }
}
